import moment from 'moment';
import { DEVICE_TYPE, MACHINE_ENVIRONMENT, MAX_CREDIT_AMOUNT, MIN_CREDIT_AMOUNT, SHARED_LINK_TEXT_KEY } from '../config/constant';
import { console_log, empty, get_data_value, get_utc_timestamp } from "./untils";

export const checkDeviceIsActive = (deviceInfo) => {
  let isActive = false;
  if (!empty(deviceInfo)) {
    let deviceType = deviceInfo.deviceType
    if (deviceType === DEVICE_TYPE.MOOVETRAX) {
      isActive = deviceInfo.status === 'online';
      if (isActive) {
        isActive = !empty(deviceInfo.lastConnect) && (moment().diff(moment(deviceInfo.lastConnect), "seconds") < 60 * 60 * 24) //&& !empty(deviceInfo.lastPosition) 
      }
    } else if (deviceType === DEVICE_TYPE.SMARTCAR) {
      //isActive = true;
      isActive = deviceInfo.status === 'online';
      if (isActive) {
        isActive = !empty(deviceInfo.lastConnect) && (moment().diff(moment(deviceInfo.lastConnect), "seconds") < 60 * 60 * 24)
      }
    } else if (deviceType === DEVICE_TYPE.TESLA) {
      //isActive = true;
      isActive = deviceInfo.status === 'online';
      if (isActive) {
        isActive = !empty(deviceInfo.lastConnect) && (moment().diff(moment(deviceInfo.lastConnect), "seconds") < 60 * 60 * 24)
      }
    }
  }
  return isActive;
}

export const checkDeviceIsOnline = (deviceInfo) => {
  if (MACHINE_ENVIRONMENT === 'dev' || MACHINE_ENVIRONMENT === 'test') {
    return true
  }

  let isActive = false;
  if (!empty(deviceInfo)) {
    let deviceType = deviceInfo.deviceType
    if (deviceType === DEVICE_TYPE.MOOVETRAX) {
      isActive = deviceInfo.status === 'online';
      if (isActive) {
        isActive = !empty(deviceInfo.lastConnect) && (moment().diff(moment(deviceInfo.lastConnect), "seconds") < 5 * 60)
      }
    } else if (deviceType === DEVICE_TYPE.SMARTCAR) {
      //isActive = true;
      isActive = deviceInfo.status === 'online';
      if (isActive) {
        isActive = !empty(deviceInfo.lastConnect) && (moment().diff(moment(deviceInfo.lastConnect), "seconds") < 60 * 60)
      }
    } else if (deviceType === DEVICE_TYPE.TESLA) {
      //isActive = true;
      isActive = deviceInfo.status === 'online';
      if (isActive) {
        isActive = !empty(deviceInfo.lastConnect) && (moment().diff(moment(deviceInfo.lastConnect), "seconds") < 4 * 60 * 60)
      }
    }
  }
  return isActive;
}

export const checkDeviceCanBeShownInMap = (deviceInfo) => {
  let isActive = false;
  if (!empty(deviceInfo)) {
    let deviceType = deviceInfo.deviceType
    if (deviceType === DEVICE_TYPE.MOOVETRAX) {
      isActive = !empty(deviceInfo.lastConnect) && (moment().diff(moment(deviceInfo.lastConnect), "seconds") < 60 * 60 * 24) //&& !empty(deviceInfo.lastPosition) 
    } else if (deviceType === DEVICE_TYPE.SMARTCAR) {
      isActive = !empty(deviceInfo.lastConnect) && (moment().diff(moment(deviceInfo.lastConnect), "seconds") < 60 * 60 * 24)
    } else if (deviceType === DEVICE_TYPE.TESLA) {
      isActive = !empty(deviceInfo.lastConnect) && (moment().diff(moment(deviceInfo.lastConnect), "seconds") < 60 * 60 * 24)
    }
  }
  return isActive;
}

export const checkDeviceChargeRequired = (deviceInfo, isAdmin) => { // this is for mandatoray
  //isRequired  -  0: normal, 1: 12hrs repeat, 2: can't close
  let isRequired = 0;
  if (isAdmin) {
    isRequired = 0;
  } else {
    let deviceCredit = Number(deviceInfo['credit']);
    //console.log("deviceCredit::::", deviceCredit)
    const monthly_cost = Number(deviceInfo['monthly_cost']);
    if (deviceInfo['deviceType'] === DEVICE_TYPE.SMARTCAR || deviceInfo['deviceType'] === DEVICE_TYPE.TESLA) {
      if (deviceCredit > 2 * monthly_cost) {
        isRequired = 2;
      }
      if (deviceInfo['billing_source'] === 'escrow' && deviceCredit <= 0) {
        // continue
      } else {
        if (!deviceInfo['is_paid']) {
          isRequired = 2;
        }
      }
    } else {
      // if (deviceCredit > 2 * monthly_cost) {
      //   isRequired = true;
      // }
      if (!deviceInfo['is_paid'] && deviceCredit >= 0 && deviceCredit <= monthly_cost) {
        isRequired = 1;
      } else if (deviceCredit > monthly_cost) {
        isRequired = 2;
      } else {
        isRequired = 0;
      }
    }
  }
  console.log(`checkDeviceChargeRequired, deviceInfo:::`, deviceInfo, isRequired)
  return 1;
}

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const SETTING_PERSIST_KEY = "moovetrax_setting_persist"
export const getSettingPersist = (key, default_value) => {
  let settingObj = {}
  let setting = window.localStorage.getItem(SETTING_PERSIST_KEY);
  if (setting) {
    settingObj = JSON.parse(setting)
  }
  if (key) {
    let value = get_data_value(settingObj, key, default_value)
    return value
  } else {
    return settingObj
  }
}
export const setSettingPersist = (key, value) => {
  let settingObj = getSettingPersist()
  settingObj[key] = value
  let setting = JSON.stringify(settingObj)
  window.localStorage.setItem(SETTING_PERSIST_KEY, setting);
  return value
}

export const getHiddenPaypalBannerDeviceIds = () => {
  let hiddenPaypalBannerDeviceIds = getSettingPersist("hiddenPaypalBannerDeviceIds", {})
  return hiddenPaypalBannerDeviceIds
}

export const setHiddenPaypalBannerDeviceIds = (hiddenPaypalBannerDeviceIds) => {
  setSettingPersist("hiddenPaypalBannerDeviceIds", hiddenPaypalBannerDeviceIds)
  return hiddenPaypalBannerDeviceIds
}

export const closePaypalBanner = (deviceId) => {
  let hiddenPaypalBannerDeviceIds = getHiddenPaypalBannerDeviceIds()
  hiddenPaypalBannerDeviceIds[deviceId] = {
    id: deviceId,
    timestamp: get_utc_timestamp()
  }
  setHiddenPaypalBannerDeviceIds(hiddenPaypalBannerDeviceIds)
}

export const showPaypalBanner = (deviceId) => {
  let hiddenPaypalBannerDeviceIds = getHiddenPaypalBannerDeviceIds()
  delete hiddenPaypalBannerDeviceIds[deviceId];
  setHiddenPaypalBannerDeviceIds(hiddenPaypalBannerDeviceIds)
}

export const clearPaypalBanner = () => {
  let hiddenPaypalBannerDeviceIds = {}
  setHiddenPaypalBannerDeviceIds(hiddenPaypalBannerDeviceIds)
}

export const checkShowPaypalBanner = (deviceId) => { // false: don't show, true: show
  let curTimestamp = get_utc_timestamp()

  let hiddenPaypalBannerDeviceIds = getHiddenPaypalBannerDeviceIds()
  console.log("hiddenPaypalBannerDeviceIds::", hiddenPaypalBannerDeviceIds)
  let deviceHiddenPaypalBanner = hiddenPaypalBannerDeviceIds[deviceId];
  if (deviceHiddenPaypalBanner) {
    let timestamp = deviceHiddenPaypalBanner['timestamp']
    if (curTimestamp - timestamp > 48 * 3600) {
      showPaypalBanner(deviceId)
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export const getFirstWordFromString = (str) => {
  try {
    if (str) {
      let string = str.toString()
      let words = string.split(" ");
      return words[0]
    }

  } catch (e) {
    console.log("getFirstWordFromString error:::", e)
  }
  return ""
}


export const copyToClipboard = (string) => {
  let textarea;
  let result;

  try {
    textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.setAttribute('contenteditable', true);
    textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand('copy');
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    //  document.body.removeChild(textarea);
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }
  return true;
}

export const trimSharedTextTemplate = (sharedTextTemplate) => {
  let sharedLinkKey = "{{shared_url}}"
  sharedTextTemplate = sharedTextTemplate.replace(sharedLinkKey, SHARED_LINK_TEXT_KEY)
  return sharedTextTemplate
}

export const getDefaultSharedTextForClipboard = () => {
  let clipboardText = "You can use the following link to:\n"
  clipboardText += "Locate.\n"
  clipboardText += "Honk The Horn.\n"
  clipboardText += "Unlock.\n\n"
  clipboardText += "This link will become active right before your trip starts\n\n"
  clipboardText += SHARED_LINK_TEXT_KEY
  return clipboardText
}

export const getTuroSummaryFirstLastWord = (tripInfo) => {
  try {
    const summaryArr = tripInfo.summary.split('-')
    const summaryStr = summaryArr[0].trim()
    const summaryWordArr = summaryStr.split(' ')
    const summaryFirstWord = summaryWordArr[0]
    const summaryLastWord = summaryWordArr[summaryWordArr.length - 1]
    return {
      summaryFirstWord: summaryFirstWord,
      summaryLastWord: summaryLastWord
    }
  } catch (e) {
    console.log(`getTuroSummaryFirstLastWord error:::`, e)
    return ""
  }
}

export const getBetweenMonthsForDateRange = (from, to) => {
  try {
    var startDate = moment(from);
    var endDate = moment(to);
    var betweenMonths = [];

    if (startDate <= endDate) {
      var date = startDate.startOf('month');
      while (date < endDate.endOf('month')) {
        betweenMonths.push(date.format('YYYY-MM'));
        date.add(1, 'month');
      }
    }
    return betweenMonths
  } catch (e) {
    console.log("getBetweenMonthsForDateRange::::", e)
    return []
  }
}

export const convertObjectToQueryString = (queryParams) => {
  try {
    let queryString = ""
    if (queryParams) {
      queryString = new URLSearchParams(queryParams).toString() //queryString: aaa=123&bbb=456
    }
    return queryString
  } catch (e) {
    console.log("convertObjectToQueryString::::", e)
    return ""
  }
}
